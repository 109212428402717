import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { ICommunityQna } from '@/interfaces/api/community/qna'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createCommunityQnaService = (client: AxiosCacheInstance) => ({
  async createInitialQuestion({
    feedId,
    ...payload
  }: ICommunityQna.API.CreateInitialQuestion.Request) {
    return await axiosHandler<ICommunityQna.API.CreateInitialQuestion.Response>(
      () => client.post(`/mobile/workCommunity/feeds/${feedId}/qna`, payload),
    )
  },

  async createFollowUpQuestion({
    feedId,
    qnaId,
    ...payload
  }: ICommunityQna.API.CreateFollowUpQuestion.Request) {
    return await axiosHandler<ICommunityQna.API.CreateFollowUpQuestion.Response>(
      () =>
        client.post(
          `/mobile/workCommunity/feeds/${feedId}/qna/${qnaId}/question`,
          payload,
        ),
    )
  },

  async createAnswer({
    feedId,
    qnaId,
    ...payload
  }: ICommunityQna.API.CreateAnswer.Request) {
    return await axiosHandler<ICommunityQna.API.CreateAnswer.Response>(() =>
      client.post(
        `/mobile/workCommunity/feeds/${feedId}/qna/${qnaId}/answer`,
        payload,
      ),
    )
  },

  async getQuestions({
    feedId,
    ...payload
  }: ICommunityQna.API.GetQuestions.Request) {
    return await axiosHandler<ICommunityQna.API.GetQuestions.Response>(() =>
      client.get(`/mobile/workCommunity/feeds/${feedId}/qna`, {
        params: payload,
      }),
    )
  },

  async getQnaDetail({
    feedId,
    qnaId,
    ...payload
  }: ICommunityQna.API.GetQnADetail.Request) {
    return await axiosHandler<ICommunityQna.API.GetQnADetail.Response>(() =>
      client.get(`/mobile/workCommunity/feeds/${feedId}/qna/${qnaId}`, {
        params: payload,
      }),
    )
  },

  async getAskedQuestions({
    memberProfileId,
  }: ICommunityQna.API.GetAskedQuestions.Request) {
    return await axiosHandler<ICommunityQna.API.GetAskedQuestions.Response>(
      () =>
        client.get(
          `/mobile/workCommunity/profile/${memberProfileId}/qnas/asked`,
        ),
    )
  },

  async getReceivedQuestions({
    memberProfileId,
  }: ICommunityQna.API.GetReceivedQuestions.Request) {
    return await axiosHandler<ICommunityQna.API.GetReceivedQuestions.Response>(
      () =>
        client.get(
          `/mobile/workCommunity/profile/${memberProfileId}/qnas/received`,
        ),
    )
  },
})
