<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 3.6C20.7732 3.6 21.4 4.2268 21.4 5V16.8061C21.4 17.5793 20.7732 18.2061 20 18.2061H14.9751C14.5981 18.2061 14.2473 18.3992 14.0457 18.7178L12.4081 21.3048C12.2487 21.5568 11.8795 21.552 11.7267 21.2959L10.2021 18.7423C10.0036 18.4097 9.64491 18.2061 9.25765 18.2061H4C3.2268 18.2061 2.6 17.5793 2.6 16.8061V5C2.6 4.2268 3.2268 3.6 4 3.6H20Z"
      :fill="color ? color : ''"
      :stroke="color ? color : stroke"
      stroke-width="1.2"
      stroke-miterlimit="10"
    />
    <circle
      cx="8"
      cy="10.8"
      r="0.5"
      :fill="stroke"
      :stroke="stroke"
    />
    <circle
      cx="12"
      cy="10.8"
      r="0.5"
      :fill="stroke"
      :stroke="stroke"
    />
    <circle
      cx="16"
      cy="10.8"
      r="0.5"
      :fill="stroke"
      :stroke="stroke"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

export default defineComponent({
  name: 'IconCommunity',
  props: {
    color: {
      type: String,
      default: null,
    },
    stroke: {
      type: String,
      default: colors['f-primary-black'],
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
})
</script>
