/**
 * number에 천 단위 마다 , 를 붙여서 string으로 반환
 * @param num
 * @returns
 */
export const numberFormat = (num: number) => {
  if (!num) {
    return '0'
  }

  return num.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
}

/**
 * 정수부분과 소수부분 사이에 <wbr />을 넣어서 string 반환
 * 정수부분에는 천 단위마다 , 를 붙임
 * (예: 12345.67 → 12,345<wbr />.67)
 * 참고: https://developer.mozilla.org/ko/docs/Web/HTML/Element/wbr
 * @param num
 * @returns
 */
export const numberFormatWithWBR = (num: number) => {
  if (!num) {
    return '0'
  }

  const [integer, decimalFraction] = num.toString().split('.')

  if (decimalFraction) {
    return `${numberFormat(Number(integer))}<wbr />.${decimalFraction}`
  }

  return `${numberFormat(num)}`
}

/**
 * 천 단위마다 , 를 넣고 10,000 이상인 경우 9,999+로 표시
 * @param num 포맷팅할 숫자
 * @param maxDisplay 최대 표시 숫자
 * @returns 포맷팅된 문자열
 */
export const numberFormatWithLimit = (
  num: number,
  maxDisplay = 9999,
): string => {
  if (!num) {
    return '0'
  }

  if (num > maxDisplay) {
    return `${numberFormat(maxDisplay)}+`
  }

  return numberFormat(num)
}
