import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { ICommunityComments } from '@/interfaces/api/community/comments'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createCommunityCommentsService = (client: AxiosCacheInstance) => ({
  async getList({
    feedId,
    ...payload
  }: ICommunityComments.API.GetList.Request) {
    return await axiosHandler<ICommunityComments.API.GetList.Response>(() =>
      client.get(`mobile/workCommunity/feeds/${feedId}/replies`, {
        params: payload,
      }),
    )
  },
})
