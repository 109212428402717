import { DeepReadonly, computed, reactive, readonly, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

import { MODE } from '@/env'
import { NavMenu } from '@/interfaces/nav-menu'
import { useSessionStore } from '@/store/modules/session'

const PRODUCTION_ALLOW_MEMBER_GROUP: number[] = []
const STAGING_ALLOW_MEMBER_GROUP: number[] = []
const DEV_ALLOW_MEMBER_GROUP: number[] = []

/**
 * 25-03-11 배포
 * 팀 관리 모든 멤버그룹 대상으로 오픈 https://fastfive.atlassian.net/browse/FFIVE-3815
 *
 * 빈 배열일 경우 모든 그룹에게 허용
 *
 * 테스트용 참고값
 * (production, staging) id 1 : 패스트파이브 주식회사
 * (development) id 83: 찐 (주) 패스트파이브
 */
const getAllowMemberGroup = () => {
  switch (MODE) {
    case 'development': {
      return DEV_ALLOW_MEMBER_GROUP
    }
    case 'staging': {
      return STAGING_ALLOW_MEMBER_GROUP
    }
    case 'production': {
      return PRODUCTION_ALLOW_MEMBER_GROUP
    }
    default: {
      return DEV_ALLOW_MEMBER_GROUP
    }
  }
}

export const ALLOW_MEMBER_GROUP = getAllowMemberGroup()

export const excludeRouteNameSet = new Set<NavMenu.RouteName>(['NotFound'])

export const navMenuOptions: (NavMenu.RootItem | NavMenu.RootBannerItem)[] = [
  {
    type: 'root',
    title: '예약',
    componentName: 'IconReservation',
    allowedMemberRoles: [],
    childs: [
      {
        type: 'child',
        routeName: 'Reserve',
        text: '예약하기',
      },
      {
        type: 'child',
        routeName: 'ReservationHistory',
        text: '예약 내역',
      },
    ],
  },
  {
    type: 'root',
    title: '베네핏',
    componentName: 'IconBenefit',
    allowedMemberRoles: [],
    childs: [
      {
        type: 'child',
        routeName: 'Benefits',
        text: '제휴 혜택',
        badge: {
          type: 'component',
          name: 'ImgBadgePlus',
          attrs: {
            width: 24,
            height: 14,
          },
        },
      },
      // {
      //   type: 'child',
      //   routeName: 'BenefitEats',
      //   text: '패파 맛집',
      // },
    ],
  },
  {
    type: 'root',
    title: '커뮤니티',
    componentName: 'IconCommunity',
    allowedMemberRoles: [],
    childs: [
      {
        type: 'child',
        routeName: 'Community',
        text: '커뮤니티',
      },
    ],
  },
  {
    type: 'root',
    title: '그룹 관리',
    componentName: 'IconGroup',
    allowedMemberRoles: ['master', 'admin'],
    disallowGuestGroup: true,
    childs: [
      {
        type: 'child',
        routeName: 'Member',
        text: '멤버 관리',
      },
      /** 4/1 배포 제외 https://fastfive.atlassian.net/browse/FFIVE-3964 */
      // {
      //   type: 'child',
      //   routeName: 'Contract',
      //   text: '계약 관리',
      // },
      {
        type: 'child',
        routeName: 'GroupTeam',
        text: '팀 관리',
        isAllowSpecificMemberGroup: true,
        disallowGroupSource: ['membership_subscription'],
      },
      {
        type: 'child',
        routeName: 'GroupCredit',
        text: '크레딧 관리',
      },

      {
        type: 'child',
        routeName: 'AdditionalServiceManage',
        text: '부가서비스 관리',
        disallowGroupSource: ['membership_subscription'],
      },
    ],
  },
  {
    type: 'banner',
    routeName: 'AdditionalService',
    image: require('@/assets/images/v2/image/home_service.png'),
    allowedMemberRoles: [],
    disallowGroupSource: ['membership_subscription'],
  },
]

export const useNavMenu = () => {
  const route = useRoute()
  const sessionStore = useSessionStore()

  const memberRole = computed(() => sessionStore.memberRoleForGroup)
  const memberGroupId = computed(() => sessionStore.memberGroup?.id)

  const state = reactive({
    lastRouteName: null as NavMenu.RouteName | null,
    menu: navMenuOptions.map((rootMenu) => {
      if (rootMenu.type === 'root') {
        return {
          ...rootMenu,
          childs: rootMenu.childs.map((childMenu) => ({
            ...childMenu,
            root: rootMenu,
          })),
        }
      }

      return rootMenu
    }),
  })

  watchEffect(() => {
    if (!route.name) {
      state.lastRouteName = null
      return
    }

    if (excludeRouteNameSet.has(route.name)) {
      return
    }

    state.lastRouteName = route.name
  })

  function isVisiable(item: NavMenu.Item | DeepReadonly<NavMenu.Item>) {
    const disallowGroupSource = item?.disallowGroupSource ?? []
    const source = sessionStore.memberGroup?.source || ''

    switch (item.type) {
      case 'banner':
      case 'root': {
        const allowedMemberRoles = item?.allowedMemberRoles ?? []
        const disallowGuestGroup = item?.disallowGuestGroup ?? false
        const role = memberRole.value ?? 'guest'

        if (
          allowedMemberRoles.length > 0 &&
          !allowedMemberRoles.includes(role)
        ) {
          return false
        }

        if (source && disallowGroupSource.includes(source)) {
          return false
        }

        if (disallowGuestGroup && sessionStore.isGuestOrLeaver) {
          return false
        }

        return true
      }

      case 'child': {
        if (item.isAllowSpecificMemberGroup) {
          if (
            memberGroupId.value &&
            ALLOW_MEMBER_GROUP.length > 0 &&
            !ALLOW_MEMBER_GROUP.includes(memberGroupId.value)
          ) {
            return false
          }
        }

        if (source && disallowGroupSource.includes(source)) {
          return false
        }

        if (item.root) {
          return isVisiable(item.root)
        }

        break
      }
    }

    return false
  }

  function isActive(item: NavMenu.Item | DeepReadonly<NavMenu.Item>) {
    switch (item.type) {
      case 'root': {
        return item.childs.some((childMenu) => isActive(childMenu))
      }

      case 'child': {
        const currentRouteName =
          route.meta?.aliasRouteNameForMenu || state.lastRouteName || route.name

        return currentRouteName === item.routeName
      }
    }
  }

  return {
    state: readonly(state),
    isVisiable,
    isActive,
  }
}
