import { MS } from '@pkg/shared/dist/time/constants'
import { AxiosRequestConfig } from 'axios'

import { ICache } from '@/interfaces/api/cache'

export const CACHE_TTL = {
  /**
   * 1분
   */
  SHORT: MS['1_MIN'],

  /**
   * 5분
   */
  MEDIUM: 5 * MS['1_MIN'],

  /**
   * 30분
   */
  LONG: 30 * MS['1_MIN'],

  /**
   * 1시간
   */
  VERY_LONG: MS['1_HOUR'],

  /**
   * 9시간
   */
  WORKING_TIME: 9 * MS['1_HOUR'],
} as const

export const API_CACHE_CONFIG = {
  'GET /mobile/workCommunity/categories': {
    ttl: CACHE_TTL.WORKING_TIME,
    reason: '커뮤니티 카테고리 목록은 자주 변경될 가능성이 낮기 때문',
  },
  'GET /some/api/endpoint': {
    reason: '',
    ttl: 10,
  },
} satisfies Record<string, ICache.CacheConfig>

export function logCacheInfo(config: AxiosRequestConfig, isHit: boolean) {
  if (process.env.NODE_ENV === 'production') return

  const method = config.method?.toUpperCase() || 'GET'
  const url = config.url || ''
  const cacheKey = `${method} ${url}`
  const cacheConfig = API_CACHE_CONFIG[cacheKey]

  // 콘솔에 로그가 많아지는 것을 방지하기 위해 정의된 것만 로깅
  if (!cacheConfig) {
    return
  }

  console.group(`🔧 API Cache ${isHit ? 'HIT' : 'MISS'}`)
  console.log(`📍 ${method} ${url}`)
  if (cacheConfig) {
    console.log(`⏱️ TTL: ${cacheConfig.ttl / 1000}s`)
    console.log(`💡 Reason: ${cacheConfig.reason}`)
  }
  console.groupEnd()
}
