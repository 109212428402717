import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill", "stroke"]
const _hoisted_3 = ["fill", "stroke"]
const _hoisted_4 = ["fill", "stroke"]
const _hoisted_5 = ["fill", "stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M20 3.6C20.7732 3.6 21.4 4.2268 21.4 5V16.8061C21.4 17.5793 20.7732 18.2061 20 18.2061H14.9751C14.5981 18.2061 14.2473 18.3992 14.0457 18.7178L12.4081 21.3048C12.2487 21.5568 11.8795 21.552 11.7267 21.2959L10.2021 18.7423C10.0036 18.4097 9.64491 18.2061 9.25765 18.2061H4C3.2268 18.2061 2.6 17.5793 2.6 16.8061V5C2.6 4.2268 3.2268 3.6 4 3.6H20Z",
      fill: _ctx.color ? _ctx.color : '',
      stroke: _ctx.color ? _ctx.color : _ctx.stroke,
      "stroke-width": "1.2",
      "stroke-miterlimit": "10"
    }, null, 8, _hoisted_2),
    _createElementVNode("circle", {
      cx: "8",
      cy: "10.8",
      r: "0.5",
      fill: _ctx.stroke,
      stroke: _ctx.stroke
    }, null, 8, _hoisted_3),
    _createElementVNode("circle", {
      cx: "12",
      cy: "10.8",
      r: "0.5",
      fill: _ctx.stroke,
      stroke: _ctx.stroke
    }, null, 8, _hoisted_4),
    _createElementVNode("circle", {
      cx: "16",
      cy: "10.8",
      r: "0.5",
      fill: _ctx.stroke,
      stroke: _ctx.stroke
    }, null, 8, _hoisted_5)
  ], 8, _hoisted_1))
}