import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { ICommunityCategorySubscription } from '@/interfaces/api/community/category-subscription'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createCommunityCategorySubscriptionService = (
  client: AxiosCacheInstance,
) => ({
  async getList(payload: ICommunityCategorySubscription.API.GetList.Request) {
    return await axiosHandler<ICommunityCategorySubscription.API.GetList.Response>(
      () =>
        client.get('/mobile/workCommunity/categories/subscribe', {
          params: payload,
        }),
    )
  },

  async subscribe(
    payload: ICommunityCategorySubscription.API.Subscribe.Request,
  ) {
    return await axiosHandler<ICommunityCategorySubscription.API.Subscribe.Response>(
      () => client.post('/mobile/workCommunity/categories/subscribe', payload),
    )
  },

  async unsubscribe(
    payload: ICommunityCategorySubscription.API.Unsubscribe.Request,
  ) {
    return await axiosHandler<ICommunityCategorySubscription.API.Unsubscribe.Response>(
      () =>
        client.delete('/mobile/workCommunity/categories/subscribe', {
          params: payload,
        }),
    )
  },
})
