import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { ICommunityFeeds } from '@/interfaces/api/community/feeds'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createCommunityFeedsService = (client: AxiosCacheInstance) => ({
  async getList(
    payload: ICommunityFeeds.API.GetList.Request,
    options?: { signal?: AbortSignal },
  ) {
    return await axiosHandler<ICommunityFeeds.API.GetList.Response>(() =>
      client.get('/mobile/workCommunity/feeds', {
        params: payload,
        signal: options?.signal,
      }),
    )
  },

  async getListByCategoryId(
    payload: ICommunityFeeds.API.GetListByCategoryId.Request,
    options?: { signal?: AbortSignal },
  ) {
    return await axiosHandler<ICommunityFeeds.API.GetListByCategoryId.Response>(
      () =>
        client.get('/mobile/workCommunity/categories/details', {
          params: payload,
          signal: options?.signal,
        }),
    )
  },

  async getMyFeeds(params: ICommunityFeeds.API.GetMyFeeds.Request) {
    return await axiosHandler<ICommunityFeeds.API.GetMyFeeds.Response>(() =>
      client.get(`/mobile/workCommunity/myFeeds`, {
        params,
      }),
    )
  },
})
