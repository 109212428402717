<template>
  <template v-if="isPreventMobileAccess || isAppFeatures">
    <div class="guide-mobile">
      <img
        src="@/assets/images/icon/icon_app.png"
        alt=""
      />
      <div class="message">
        <template v-if="isPreventMobileAccess">
          모바일 환경에 최적화된<br />
          패스트파이브 앱을 사용해보세요.
        </template>
        <template v-else-if="isAppFeatures">
          해당 링크는 패스트파이브 앱 설치 후<br />
          모바일 환경에서 열어주세요.
        </template>
      </div>
      <button-component
        class="button"
        color="primary-black"
        :height="50"
        :fontSize="20"
        text="APP 열기"
        @submit="onOpenAppClick"
      />
    </div>
  </template>
  <template v-else-if="isServiceUnavailable">
    <ServiceUnavailable />
  </template>
  <template v-else-if="globalStore.isInitialized">
    <router-view />

    <MessageModal
      v-model:isShow="globalModal.isShow"
      :title="globalModal.title"
      :message="globalModal.message"
      :cancellable="globalModal.cancellable"
      :confirmButtonText="globalModal.confirmButtonText"
      :cancelButtonText="globalModal.cancelButtonText"
      :onConfirm="globalModal.onConfirm"
      :onCancel="globalModal.onCancel"
      @update:isShow="(isShow) => !isShow && globalModal.$reset()"
    />

    <template v-if="isLoading">
      <Loading />
    </template>
  </template>
  <template v-else>
    <!-- 로딩 중 -->
    <Loading />
  </template>
</template>

<script>
import Bowser from 'bowser'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import ButtonComponent from '@/components/common/Button'
import Loading from '@/components/v2/Loading.vue'
import MessageModal from '@/components/v2/modal/MessageModal.vue'
import ServiceUnavailable from '@/views/error/ServiceUnavailable.vue'

import { useGlobalLoading } from '@/hooks/global-loading'
import { useGlobalModal } from '@/hooks/global-modal'
import { setRedirectUrl } from '@/libs/storage/helpers/redirect-url'
import { setSurvey } from '@/libs/survey'
import { useApplink } from '@/plugins/applink'
import { useAppConfigStore } from '@/store/modules/app-config'
import { useGlobalStore } from '@/store/modules/global'
import { useSessionStore } from '@/store/modules/session'

export default {
  name: 'App',
  components: {
    ButtonComponent,
    MessageModal,
    Loading,
    ServiceUnavailable,
  },
  setup() {
    setSurvey()
    const route = useRoute()
    const sessionStore = useSessionStore()
    const globalStore = useGlobalStore()
    const globalModal = useGlobalModal()
    const globalLoading = useGlobalLoading()
    const appConfigStore = useAppConfigStore()

    const applinkState = useApplink()

    const browser = computed(() => {
      return Bowser.parse(navigator.userAgent)
    })

    const isPreventMobileAccess = computed(() => {
      if (route.meta.isAccessibleFromMobile) {
        return false
      }

      return browser.value.platform.type === 'mobile'
    })

    const isAppFeatures = computed(() => applinkState.isAppFeatures)

    const isLoading = computed(() => globalLoading.isLoading)

    const isServiceUnavailable = computed(() => {
      return (
        appConfigStore.inProgressSystemMaintenance ||
        globalStore.isServiceUnavailableForServerChecking
      )
    })

    sessionStore
      .initialize()
      .then(() => {
        globalStore.completeInitialize()

        if (applinkState.redirectUrl) {
          if (!sessionStore.isLogin) {
            setRedirectUrl(applinkState.redirectUrl)
          }

          applinkState.redirectUrl = undefined
        }
      })
      .catch((error) => {
        globalStore.completeInitialize()

        console.error(error)
      })

    function onOpenAppClick() {
      switch (browser.value.os.name) {
        case 'Android':
          {
            window.setTimeout(() => {
              window.location = 'market://details?id=com.fastfive.work'
            }, 25)
            window.location =
              'intent://#Intent;scheme=fastfivework;package=com.fastfive.work;end'
          }
          break
        case 'iOS':
          {
            window.setTimeout(() => {
              window.location = 'itms-apps://itunes.apple.com/app/id1474593515'
            }, 25)
            window.location = 'fastfivework://open'
          }
          break
      }
    }

    return {
      globalModal,
      globalStore,
      isPreventMobileAccess,
      isAppFeatures,
      isLoading,
      isServiceUnavailable,
      onOpenAppClick,
    }
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/main.scss';
</style>

<style lang="scss" scoped>
.guide-mobile {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > img {
    width: 158px;
    height: 158px;
  }

  > .message {
    margin-top: 56px;
    white-space: pre-line;
    text-align: center;

    @include font_ko(18px, 400);
  }

  > .button {
    margin-top: 25px;
    width: 254px;
    border-radius: 25px;
  }
}
</style>
