<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.break]: !!breakPoint,
    }"
  >
    <div :class="$style.scrollView">
      <div :class="$style.box">
        <div
          v-if="hasHeader"
          :class="$style.header"
        >
          <div
            v-if="hasClose"
            :class="$style.close"
            @click="onCloseClick"
          >
            <IconClose />
          </div>
          <div
            v-if="title"
            :class="$style.title"
          >
            {{ title }}
          </div>
          <div
            v-if="subtitle"
            :class="$style.subtitle"
          >
            {{ subtitle }}
          </div>
        </div>

        <SimpleBar
          :class="{
            [$style.body]: true,
            [$style.scrollable]: bodyScrollable,
          }"
        >
          <slot :bodyStyle="$style.bodyStyle" />
        </SimpleBar>

        <div
          v-if="hasFooter"
          :class="$style.footer"
        >
          <div
            v-if="btns"
            :class="$style.btns"
          >
            <template
              v-for="(btn, key) in btns"
              :key="key"
            >
              <Button
                :class="$style.btn"
                frame="flexable"
                type="button"
                size="large"
                :theme="btn.theme"
                :disabled="btn.disabled"
                @click="onBtnClick(btn.onClick)"
              >
                {{ btn.text }}
              </Button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div :class="$style.dimmed" />
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from 'vue'

import SimpleBar from '@/components/v2/SimpleBar.vue'
import Button from '@/components/v2/button/Button.vue'
import IconClose from '@/components/v2/icon/IconClose.vue'
import Header from '@/containers/layout/Header.vue'

import { useWindowSize } from '@/hooks/use-window-size'
import { ModalV2 } from '@/interfaces/components/v2/modal'
import { withPx } from '@/libs/css'
import { colors } from '@/plugins/color'
import { useGlobalStore } from '@/store/modules/global'

export default defineComponent({
  components: {
    Header,
    Button,
    IconClose,
    SimpleBar,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    subtitleColor: {
      type: String,
      default: 'f-primary-black',
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
    btns: {
      type: Array as PropType<ModalV2.Props.Btns>,
      default: () => [],
    },
    width: {
      type: String,
      default: '540px',
    },
    breakPoint: {
      type: Number,
    },
    bodyScrollable: {
      type: Boolean,
      default: false,
    },
    bodyHeight: {
      type: String,
      default: 'auto',
    },
    noControlScrollBar: {
      type: Boolean,
      default: false,
    },
    onShow: Function,
    onHide: Function,
    zIndex: {
      type: Number,
      default: 105,
    },
  },
  emits: {
    'update:isShow': (val: boolean) => typeof val === 'boolean',
    'click:close': () => true,
  },
  setup(props, { emit }) {
    const globalStore = useGlobalStore()
    const { windowHeight } = useWindowSize(1024)

    const hasHeader = computed(() => {
      return !!props.title || !!props.subtitle || props.hasClose
    })

    const hasFooter = computed(() => {
      return props.btns.length > 0
    })

    const cssVars = computed(() => {
      return {
        width: props.width,
        breakPoint: withPx(props.breakPoint) || 'auto',
        bodyHeight: props.bodyHeight,
        windowHeight: withPx(windowHeight.value),
        subtitleColor: colors[props.subtitleColor],
        zIndex: props.zIndex || 105,
        titlePaddingRight: props.hasClose ? '32px' : '0px',
      }
    })

    onMounted(() => {
      if (!props.noControlScrollBar) {
        globalStore.enableScrollBar(false)
      }
    })

    onBeforeUnmount(() => {
      if (!props.noControlScrollBar) {
        globalStore.enableScrollBar()
      }
    })

    /**
     * @public
     */
    function show() {
      emit('update:isShow', true)
    }

    /**
     * @public
     */
    function hide() {
      emit('update:isShow', false)
    }

    function onCloseClick() {
      emit('click:close')
      hide()
    }

    function onBtnClick(callback?: ModalV2.BtnClickCallback) {
      callback?.(hide)
    }

    return {
      hasHeader,
      hasFooter,
      cssVars,
      show,
      hide,
      onCloseClick,
      onBtnClick,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  position: fixed;
  z-index: v-bind('cssVars.zIndex');
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  height: 100vh;

  .scrollView {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: v-bind('cssVars.windowHeight');
    padding: 0;

    @include respond_up(md) {
      min-height: 100%;
      padding: 32px;
    }

    .box {
      position: relative;
      z-index: 1;
      background-color: $f-primary-white;
      overflow: hidden;
      padding: 32px 20px 20px;
      width: 100vw;
      border-radius: 0;

      @include respond_up(md) {
        padding: 32px;
        width: v-bind('cssVars.width');
        border-radius: 16px;
      }

      .header {
        position: relative;

        .title {
          @include font_v2('ko', 18px, 700);
          padding-right: v-bind('cssVars.titlePaddingRight');
          color: $f-primary-black;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .subtitle {
          @include font_v2('ko', 15px, 400);
          color: v-bind('cssVars.subtitleColor');
          word-break: break-all;
          white-space: pre-line;
          margin-top: 8px;
        }

        .close {
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .body {
        /**
        모달 헤더 + 푸터 높이 = 128px
         */
        height: calc(v-bind('cssVars.windowHeight') - 128px);
        margin-top: 24px;

        @include respond_up(md) {
          height: v-bind('cssVars.bodyHeight');
        }

        &.scrollable {
          margin: 0 -32px -32px;
          padding: 0 32px 32px;
        }

        .bodyStyle {
          margin-top: 24px;
        }
      }

      .footer {
        margin-top: 32px;

        .btns {
          display: flex;
          gap: 10px;

          & button {
            width: 100%;
          }

          @include respond_up(md) {
            justify-content: end;

            & button {
              width: fit-content;
            }
          }
        }
      }
    }
  }
  &.break {
    width: 100vw;

    .scrollView {
      min-width: v-bind('cssVars.breakPoint');
    }
  }
}

.dimmed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: v-bind('cssVars.zIndex - 1');
  background-color: $f-trans-black-70;
  height: 100vh;
}
</style>
