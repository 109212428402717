import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Suspense as _Suspense } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AccountPopup = _resolveComponent("AccountPopup")!
  const _component_SelectBox = _resolveComponent("SelectBox")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_invitation_container = _resolveComponent("invitation-container")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_component_router_link, {
      to: { name: _ctx.hasProfile ? 'Home' : 'SignIn' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.logo),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click:logo')))
        }, [
          _createElementVNode("img", {
            src: _ctx.images.logo,
            alt: "logo"
          }, null, 8, _hoisted_1)
        ], 2)
      ]),
      _: 1
    }, 8, ["to"]),
    (!_ctx.noMenu)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.headerMenu)
        }, [
          (_ctx.hasProfile && _ctx.sessionStore.isLogin)
            ? (_openBlock(), _createBlock(_component_SelectBox, {
                key: 0,
                open: _ctx.state.isShowProfile,
                "onUpdate:open": [
                  _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.isShowProfile) = $event)),
                  _ctx.onProfileMenuClick
                ],
                class: _normalizeClass(_ctx.$style.item),
                popupPosition: "right"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass([_ctx.$style.label, _ctx.$style.ellipsis])
                  }, _toDisplayString(_ctx.memberGroup ? _ctx.computedGroupName : '소속된 그룹이 없습니다'), 3)
                ]),
                popup: _withCtx(({ onInsideClick }) => [
                  _createVNode(_component_AccountPopup, {
                    profileThumbnail: _ctx.user?.imageUrl,
                    username: _ctx.user?.name,
                    occupation: _ctx.user?.occupation,
                    email: _ctx.user?.email,
                    memberGroups: _ctx.memberGroups,
                    selectedMemberGroupId: _ctx.selectedMemberGroupId,
                    onClick: onInsideClick,
                    "onClick:logout": _ctx.onLogoutClick,
                    "onClick:myPage": _ctx.onMyPageClick,
                    "onClick:subscriptionsMy": _ctx.onMySubscriptionsClick,
                    "onClick:subscriptions": _ctx.onSubscriptionsClick,
                    "onClick:resetPassword": _ctx.onResetPasswordClick,
                    "onChange:memberGroup": _ctx.onMemberGroupChange,
                    "onClick:invite": _ctx.onInvitationClick
                  }, null, 8, ["profileThumbnail", "username", "occupation", "email", "memberGroups", "selectedMemberGroupId", "onClick", "onClick:logout", "onClick:myPage", "onClick:subscriptionsMy", "onClick:subscriptions", "onClick:resetPassword", "onChange:memberGroup", "onClick:invite"])
                ]),
                _: 1
              }, 8, ["open", "class", "onUpdate:open"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.state.isShowInviteModal)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 1,
          isShow: _ctx.state.isShowInviteModal,
          title: '초대 링크 입력',
          subtitle: '관리 멤버에게 받은 초대 링크를 복사하여 아래 입력해 주세요.',
          hasFooter: true,
          btns: [
        {
          text: '취소',
          theme: 'border-gray-35',
          onClick: _ctx.onInvitationCancel,
        },
        {
          text: '다음',
          theme: 'primary-black',
          disabled: !_ctx.state.validInvitationCode,
          onClick: _ctx.onInvitationAccept,
        },
      ]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Input, {
              modelValue: _ctx.state.invitationCode,
              warningText: 
          _ctx.state.invitationCode.length && !_ctx.state.validInvitationCode
            ? '올바른 초대 코드를 입력해 주세요.'
            : ''
        ,
              validator: _ctx.invitationCodeValidator,
              "first-focus": true,
              placeholder: "https://fastfive.app.link/invitation?code=abcde23456",
              onValidate: _cache[2] || (_cache[2] = (valid) => (_ctx.state.validInvitationCode = valid)),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = (value) => (_ctx.state.invitationCode = value))
            }, null, 8, ["modelValue", "warningText", "validator"]),
            _createElementVNode("span", {
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.moveFaqInvitationCode && _ctx.moveFaqInvitationCode(...args))),
              class: _normalizeClass(_ctx.$style.questionLink)
            }, [
              _createElementVNode("img", {
                src: _ctx.images.iconQuestion,
                alt: "'물음표 아이콘'"
              }, null, 8, _hoisted_2),
              _cache[6] || (_cache[6] = _createElementVNode("span", null, "유효하지 않은 초대링크라고 뜨나요?", -1))
            ], 2)
          ]),
          _: 1
        }, 8, ["isShow", "btns"]))
      : _createCommentVNode("", true),
    (_ctx.state.isShowJoinGroupModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(), _createBlock(_Suspense, null, {
            default: _withCtx(() => [
              _createVNode(_component_invitation_container, {
                "invitation-code": _ctx.state.invitationCode,
                rejectedModalTitle: "소속/회사 정보가 다르군요!",
                onComplete: _ctx.onJoinGroupComplete,
                onInvalid: _cache[5] || (_cache[5] = 
            () => {
              _ctx.state.isShowJoinGroupModal = false
              _ctx.state.isShowInviteModal = true
            }
          )
              }, null, 8, ["invitation-code", "onComplete"])
            ]),
            _: 1
          }))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}