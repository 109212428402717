import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { ICommunityMember } from '@/interfaces/api/community/member-profile'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createCommunityMemberProfileService = (
  client: AxiosCacheInstance,
) => ({
  async getProfile(payload: ICommunityMember.API.GetProfile.Request) {
    return await axiosHandler<ICommunityMember.API.GetProfile.Response>(() =>
      client.get('/mobile/workCommunity/profile', {
        params: payload,
      }),
    )
  },

  async getOtherMemberProfile(
    payload: ICommunityMember.API.GetOtherMemberProfile.Request,
  ) {
    return await axiosHandler<ICommunityMember.API.GetOtherMemberProfile.Response>(
      () =>
        client.get(
          `/mobile/workCommunity/profile/${payload.targetMemberProfileId}`,
          {
            params: {
              myMemberGroupId: payload.myMemberGroupId,
            },
          },
        ),
    )
  },

  async checkNickname({
    nickname,
  }: ICommunityMember.API.CheckNickname.Request) {
    return await axiosHandler<ICommunityMember.API.CheckNickname.Response>(() =>
      client.get(`/mobile/workCommunity/profile/${nickname}/check`),
    )
  },

  async createProfile(payload: ICommunityMember.API.CreateProfile.Request) {
    return await axiosHandler<ICommunityMember.API.CreateProfile.Response>(() =>
      client.post('/mobile/workCommunity/profile', payload),
    )
  },

  async updateProfile(payload: ICommunityMember.API.UpdateProfile.Request) {
    const hasFileId = payload.uploadedFileId !== undefined
    const hasFileName = payload.uploadedFileName !== undefined

    // 둘 다 있거나 둘 다 없어야 함
    if (hasFileId !== hasFileName) {
      throw new Error(
        '이미지 업데이트 시 uploadedFileId와 uploadedFileName이 모두 필요합니다.',
      )
    }

    return await axiosHandler<ICommunityMember.API.UpdateProfile.Response>(() =>
      client.patch('/mobile/workCommunity/profile', payload),
    )
  },
})
