import { Ref, computed, onMounted, onUnmounted, ref } from 'vue';

export function useWindowSize(threshold: number, debounceTime: number = 200) {
  const windowWidth: Ref<number> = ref(window.innerWidth)
  const windowHeight: Ref<number> = ref(window.innerHeight)

  let debounceTimeout: ReturnType<typeof setTimeout>

  const updateWindowWidth = () => {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(() => {
      windowWidth.value = window.innerWidth
      windowHeight.value = window.innerHeight
    }, debounceTime)
  }

  const isBelowThreshold = computed(() => windowWidth.value <= threshold)

  onMounted(() => {
    window.addEventListener('resize', updateWindowWidth)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', updateWindowWidth)
  })

  return { windowWidth, windowHeight, isBelowThreshold }
}