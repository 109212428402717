/**
 * `vue-cli-service serve` 로 실행된 경우
 */
export const IS_SERVE = process.env.VUE_APP_IS_SERVE === 'true'

export const IS_TEST = process.env.VUE_APP_IS_TEST === 'true'

export const GIT_REF = process.env.VUE_APP_GIT_REF || 'unset'

export const NODE_ENV = (process.env.NODE_ENV || 'development') as
  | 'development'
  | 'production'

export const MODE = (process.env.VUE_APP_MODE || 'development') as
  | 'development'
  | 'staging'
  | 'production'

export const API_URL = (process.env.VUE_APP_API_BASE_URL ||
  'https://api-dev.slowfive.com') as
  | 'https://api-dev.slowfive.com'
  | 'https://api-dev-a.slowfive.com'
  | 'https://api-dev-b.slowfive.com'
  | 'https://api-dev-c.slowfive.com'
  | 'https://api-stage.slowfive.com'
  | 'https://api.fastfive.co.kr'

/**
 * `vue-cli-service serve` 로 실행된 경우 baseURL을 /api로 변경하고 proxy 사용.
 */
export const API_BASE_URL =
  IS_SERVE || IS_TEST ? `${window.location.origin}/api` : API_URL

export const DB_TARGET: typeof MODE = (() => {
  switch (API_URL) {
    case 'https://api.fastfive.co.kr':
      return 'production'

    case 'https://api-stage.slowfive.com':
      return 'staging'

    default:
      return 'development'
  }
})()

export const FIREBASE_MESSAGING_VAPID_KEY =
  process.env.VUE_APP_FIREBASE_MESSAGING_VAPID_KEY

export const WORKANYWHERE_ACCESS_KEY =
  process.env.VUE_APP_WORKANYWHERE_ACCESS_KEY

export const KCP_PLUGIN_URL = (process.env.VUE_APP_KCP_PLUGIN_URL ||
  'https://testspay.kcp.co.kr/plugin/kcp_spay_hub.js') as
  | 'https://testspay.kcp.co.kr/plugin/kcp_spay_hub.js'
  | 'https://spay.kcp.co.kr/plugin/kcp_spay_hub.js'

export const REFINER_KEY = process.env.VUE_APP_REFINER_KEY

export const SERVICE_AREA_PREFIX = MODE === 'production' ? '' : '(신규)'

/**
 * TODO
 * propduction의 경우 github secrets 에서 접근 가능하도록 작업해야함
 */
export const NCP_CLIENT_ID = process.env.VUE_APP_NAVER_MAP_KEY
