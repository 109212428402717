import dayjs from 'dayjs'
import _refiner from 'refiner-js'

import { REFINER_KEY } from '@/env'
import { MemberRole } from '@/interfaces/global'
import { SessionStore } from '@/interfaces/store/session'
import { getRemainingDaysFromToday } from '@/libs/date'
import { useSessionStore } from '@/store/modules/session'

/**
 * 초기 세팅
 */
export const setSurvey = () => {
  _refiner('setProject', REFINER_KEY)
}

/**
 * 고유한 유저 정보 세팅
 * @param params
 */
export const setSurveyUser = <T>(params: T): void => {
  _refiner('identifyUser', params)
}

/**
 * 로그아웃 시 초기화
 */
export const resetSurvey = () => {
  _refiner('resetUser')
}

/**
 * 모든 설문을 닫기
 */
export const closeAllSurvey = () => {
  _refiner('closeForm')
}

/**
 * [FFIVE-3654] https://fastfive.atlassian.net/browse/FFIVE-3654
 */
type RefinerTraits = {
  id: number
  role: MemberRole
  memberGroupId: number
  memberGroupName: string
  contractedBranches: string | null
  contractMinStartDate: string | null
  contractMaxEndDate: string | null
  remainingContractDays: number | null
}
export const setSurveyUserForEveryone = () => {
  const sessionStore = useSessionStore()
  if (!sessionStore || !sessionStore.isLogin || !sessionStore.user) {
    return
  }

  const id = sessionStore.user.memberId
  const role = sessionStore.user.role
  const memberGroupId = sessionStore.memberGroup?.id || 0
  const memberGroupName = sessionStore?.memberGroup?.name || ''
  const memberGroups = sessionStore.memberGroups
  const contractedBranches = getContractedBranches(sessionStore)
  const { contractMinStartDate, contractMaxEndDate } = getContractDate(
    memberGroupId,
    memberGroups,
  )
  const remainingContractDays = getRemainingContractDays(
    memberGroupId,
    memberGroups,
  )

  const params: RefinerTraits = {
    id,
    role,
    memberGroupId,
    memberGroupName,
    contractedBranches,
    contractMinStartDate,
    contractMaxEndDate,
    remainingContractDays,
  }

  setSurveyUser(params)
}

const getContractedBranches = (sessionStore: SessionStore.State) => {
  return (
    sessionStore.accessibleBranches
      .map(({ contracts, name }) => {
        const isWithinContract = contracts?.some(({ startDate, closeDate }) =>
          dayjs().isBetween(dayjs(startDate), dayjs(closeDate), 'day', '[]'),
        )

        return isWithinContract ? name : null
      })
      .filter((name) => name !== null)
      .join(',') || null
  )
}

const getContractDate = (
  memberGroupId: number,
  memberGroups: SessionStore.State.MemberGroupListItem[],
) => {
  const contract = memberGroups.find(
    (group) => group.memberGroupId === memberGroupId,
  )?.contract
  const contractMinStartDate = contract?.minStartDate || null
  const contractMaxEndDate = contract?.maxEndDate || null

  return { contractMinStartDate, contractMaxEndDate }
}

const getRemainingContractDays = (
  memberGroupId: number,
  memberGroups: SessionStore.State.MemberGroupListItem[],
) => {
  const maxContractEndDate = getValidContractEndDate(
    memberGroups,
    memberGroupId,
  )
  const remainingContractDays = maxContractEndDate
    ? getRemainingDaysFromToday(maxContractEndDate)
    : null

  return remainingContractDays
}

const getValidContractEndDate = (
  memberGroups: SessionStore.State.MemberGroupListItem[],
  memberGroupId: number | undefined,
) => {
  const selectedGroup = memberGroups.find(
    (item) => item.memberGroupId === memberGroupId,
  )
  return selectedGroup ? selectedGroup?.contract?.maxEndDate : null
}
