import { axiosHandler } from '@/libs/api/axios-handler'

export const createService = (client) => {
  return {
    async uploadFiles(form) {
      return await client.post(`/file/public`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },

    async uploadPrivateFiles(form) {
      return axiosHandler(() => {
        return client.post(`/file/private`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      })
    },
  }
}
