import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["href", "onClickCapture", "onMouseenter"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["href", "onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_IconQuestion = _resolveComponent("IconQuestion")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.navContainer)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navs, (nav, navKey) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: navKey }, [
          (nav.type === 'root' && _ctx.navMenu.isVisiable(nav) && nav.title)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.menu)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.title)
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(nav.componentName), {
                    class: _normalizeClass(_ctx.$style.icon)
                  }, null, 8, ["class"])),
                  _createElementVNode("span", null, _toDisplayString(nav.title), 1)
                ], 2),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nav.childs, (child, childKey) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: childKey }, [
                    (_ctx.navMenu.isVisiable(child))
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: { name: child.routeName },
                          custom: "",
                          "active-class": _ctx.$style.active
                        }, {
                          default: _withCtx(({ href, navigate }) => [
                            _createElementVNode("a", {
                              href: href,
                              class: _normalizeClass({
                    [_ctx.$style.item]: true,
                    [_ctx.$style.active]: _ctx.navMenu.isActive(child),
                  }),
                              onClickCapture: _withModifiers(
                    () => {
                      navigate()
                      _ctx.onRouterLinkClick(child)
                    }
                  , ["prevent"]),
                              onMouseenter: () => _ctx.setHoverRouteName(child),
                              onMouseleave: _cache[0] || (_cache[0] = () => _ctx.removeHoverRouteName())
                            }, [
                              _createElementVNode("span", null, _toDisplayString(child.text), 1),
                              (child.badge)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    (child.badge.type)
                                      ? (_openBlock(), _createBlock(_component_Badge, {
                                          key: 0,
                                          class: _normalizeClass(_ctx.$style.linkBadge),
                                          color: "f-gray-40",
                                          hoverColor: "f-primary-black",
                                          activeColor: "f-primary-black",
                                          active: _ctx.navMenu.isActive(child) || _ctx.isHover(child)
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(), _createBlock(_resolveDynamicComponent(child.badge.name), _mergeProps({ ref_for: true }, child.badge.attrs), null, 16))
                                          ]),
                                          _: 2
                                        }, 1032, ["class", "active"]))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                : _createCommentVNode("", true)
                            ], 42, _hoisted_1)
                          ]),
                          _: 2
                        }, 1032, ["to", "active-class"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ], 2))
            : _createCommentVNode("", true),
          (nav.type === 'banner' && _ctx.navMenu.isVisiable(nav))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_router_link, {
                  to: { name: nav.routeName },
                  custom: ""
                }, {
                  default: _withCtx(({ href, navigate }) => [
                    _createElementVNode("a", {
                      href: href,
                      onClick: navigate,
                      class: _normalizeClass(_ctx.$style.banner)
                    }, [
                      _createElementVNode("img", {
                        src: nav.image,
                        alt: "bannerImg",
                        class: _normalizeClass(_ctx.$style.bannerImg)
                      }, null, 10, _hoisted_4)
                    ], 10, _hoisted_3)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.footer)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.item)
      }, [
        _createVNode(_component_IconQuestion, {
          class: _normalizeClass(_ctx.$style.icon)
        }, null, 8, ["class"]),
        _cache[1] || (_cache[1] = _createTextVNode(" 궁금한 점이 있으신가요? "))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.item)
      }, [
        _createVNode(_component_Button, {
          class: _normalizeClass(_ctx.$style.btn),
          size: "large",
          theme: "secondary-extension-orange",
          onClick: _ctx.onInquiryPage
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: _normalizeClass(_ctx.$style.icon),
              src: _ctx.images.icon_request_image,
              alt: "icon"
            }, null, 10, _hoisted_5),
            _cache[2] || (_cache[2] = _createElementVNode("span", null, "1:1 문의하기", -1))
          ]),
          _: 1
        }, 8, ["class", "onClick"])
      ], 2)
    ], 2)
  ], 2))
}