import fastfive from '@fastfive-dev/frontend-library/dist/fastfive'
import dayjs, { ConfigType } from 'dayjs'

import '@/plugins/dayjs'
// 플러그인 import가 누락될 수도 있어서 방어코드 추가
import { Timezone } from '@/plugins/dayjs'

/**
 * dayjs를 한국 시간으로만 사용할 때 사용
 *
 * @see https://day.js.org/docs/en/timezone/converting-to-zone
 */
export function fromKST(date?: ConfigType) {
  // 입력값은 항상 KST. timezone만 바꿔줌
  if (!!date) {
    return dayjs(date).tz(Timezone['Asia/Seoul'], true)
  }

  // 입력값이 없거나 잘못된 경우 디바이스 타임존이 적용되기때문에 offset을 KST로 변경함
  return dayjs(date).tz(Timezone['Asia/Seoul'])
}

/**
 * 입력값을 무조건 UTC라고 판단하고 KST로 변경하는 함수
 *
 * - 입력값이 UTC인경우 사용
 *
 * @see https://day.js.org/docs/en/timezone/converting-to-zone
 */
export function fromUTC(date?: ConfigType) {
  return dayjs.utc(date).tz(Timezone['Asia/Seoul'])
}

/**
 * 생년월일 입력 포맷을 ISO 포맷으로 변환하는 함수
 *
 * 생년월일이 유효하지 않은 경우 null을 반환합니다.
 *
 * - 생년월일 포맷 : YYYY. MM. DD
 * - 반환되는 포맷 : YYYY-MM-DD
 */
export function birthdayFormatToISO(input: string | null | undefined = null) {
  if (!input || typeof input !== 'string') {
    return null
  }

  const [year, month, day] = input.split('. ').map((row) => Number(row))

  const birthday = fromKST(new Date(year, month - 1, day))

  if (!birthday.isValid()) {
    return null
  }

  return birthday.format('YYYY-MM-DD')
}

export const parseTimestamp = fastfive.utils.date.parseTimestamp

export const reservationTimeFormat = ({
  startAt,
  endAt,
  format,
}: {
  startAt: dayjs.Dayjs
  endAt: dayjs.Dayjs
  format:
    | 'H:mm - H:mm'
    | 'A h:mm - A h:mm'
    | 'YYYY.MM.DD dd H:mm - H:mm'
    | 'YYYY. M. D\nH:mm - H:mm'
    | 'YYYY. MM. DD  H:mm - H:mm'
    | 'YYYY. MM. DD  H:mm - YYYY. MM. DD  H:mm'
}) => {
  const separator = ' - '
  const isMidnight = startAt.date() !== endAt.date()

  let [startFormat, endFormat] = format.split(separator)

  if (isMidnight) {
    endFormat = endFormat.replace(/H/g, 'k')
  }

  return [startAt.format(startFormat), endAt.format(endFormat)].join(separator)
}

export const getRemainingDaysFromToday = (targetDate: string | dayjs.Dayjs) => {
  const today = dayjs().startOf('day')
  const remainingDays = dayjs(targetDate).diff(today, 'day')

  return remainingDays
}

/**
 * 날짜를 상대적 시간 또는 절대적 시간으로 포맷팅하는 함수
 *
 * @param date 포맷팅할 날짜 (string 또는 dayjs 객체)
 * @returns 포맷팅된 문자열
 *
 * 1분 이전: "조금 전"
 * 1시간 이전: "n분 전"
 * 24시간 이전: "n시간 전"
 * 24시간 이후, 같은 해: "M월 D일 HH:mm"
 * 다른 해: "YYYY년 M월 D일"
 */
export const formatRelativeTime = (date: ConfigType): string => {
  const targetDate = fromKST(date)
  const now = fromKST()

  // 유효하지 않은 날짜 처리
  if (!targetDate.isValid()) {
    return '-'
  }

  const diffMinutes = now.diff(targetDate, 'minute')
  const diffHours = now.diff(targetDate, 'hour')
  const isSameYear = now.year() === targetDate.year()

  // 미래 시간은 절대 시간으로 표시
  if (diffMinutes < 0) {
    return isSameYear
      ? targetDate.format('M월 D일 H:mm')
      : targetDate.format('YYYY년 M월 D일')
  }

  // 1분 미만
  if (diffMinutes === 0) {
    return '조금 전'
  }

  // 1시간 미만
  if (diffMinutes < 60) {
    return `${diffMinutes}분 전`
  }

  // 24시간 미만
  if (diffHours < 24) {
    return `${diffHours}시간 전`
  }

  // 24시간 이후
  return isSameYear
    ? targetDate.format('M월 D일 H:mm')
    : targetDate.format('YYYY년 M월 D일')
}
