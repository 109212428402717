
import {
  PropType,
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from 'vue'

import SimpleBar from '@/components/v2/SimpleBar.vue'
import Button from '@/components/v2/button/Button.vue'
import IconClose from '@/components/v2/icon/IconClose.vue'
import Header from '@/containers/layout/Header.vue'

import { useWindowSize } from '@/hooks/use-window-size'
import { ModalV2 } from '@/interfaces/components/v2/modal'
import { withPx } from '@/libs/css'
import { colors } from '@/plugins/color'
import { useGlobalStore } from '@/store/modules/global'

const __default__ = defineComponent({
  components: {
    Header,
    Button,
    IconClose,
    SimpleBar,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    subtitleColor: {
      type: String,
      default: 'f-primary-black',
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
    btns: {
      type: Array as PropType<ModalV2.Props.Btns>,
      default: () => [],
    },
    width: {
      type: String,
      default: '540px',
    },
    breakPoint: {
      type: Number,
    },
    bodyScrollable: {
      type: Boolean,
      default: false,
    },
    bodyHeight: {
      type: String,
      default: 'auto',
    },
    noControlScrollBar: {
      type: Boolean,
      default: false,
    },
    onShow: Function,
    onHide: Function,
    zIndex: {
      type: Number,
      default: 105,
    },
  },
  emits: {
    'update:isShow': (val: boolean) => typeof val === 'boolean',
    'click:close': () => true,
  },
  setup(props, { emit }) {
    const globalStore = useGlobalStore()
    const { windowHeight } = useWindowSize(1024)

    const hasHeader = computed(() => {
      return !!props.title || !!props.subtitle || props.hasClose
    })

    const hasFooter = computed(() => {
      return props.btns.length > 0
    })

    const cssVars = computed(() => {
      return {
        width: props.width,
        breakPoint: withPx(props.breakPoint) || 'auto',
        bodyHeight: props.bodyHeight,
        windowHeight: withPx(windowHeight.value),
        subtitleColor: colors[props.subtitleColor],
        zIndex: props.zIndex || 105,
        titlePaddingRight: props.hasClose ? '32px' : '0px',
      }
    })

    onMounted(() => {
      if (!props.noControlScrollBar) {
        globalStore.enableScrollBar(false)
      }
    })

    onBeforeUnmount(() => {
      if (!props.noControlScrollBar) {
        globalStore.enableScrollBar()
      }
    })

    /**
     * @public
     */
    function show() {
      emit('update:isShow', true)
    }

    /**
     * @public
     */
    function hide() {
      emit('update:isShow', false)
    }

    function onCloseClick() {
      emit('click:close')
      hide()
    }

    function onBtnClick(callback?: ModalV2.BtnClickCallback) {
      callback?.(hide)
    }

    return {
      hasHeader,
      hasFooter,
      cssVars,
      show,
      hide,
      onCloseClick,
      onBtnClick,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "38c73686": (_ctx.cssVars.zIndex),
  "c880e488": (_ctx.cssVars.windowHeight),
  "1290be3e": (_ctx.cssVars.width),
  "2709cd9e": (_ctx.cssVars.titlePaddingRight),
  "f5322b74": (_ctx.cssVars.subtitleColor),
  "7237bb2e": (_ctx.cssVars.bodyHeight),
  "4835ccd4": (_ctx.cssVars.breakPoint),
  "4140f71b": (_ctx.cssVars.zIndex - 1)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__