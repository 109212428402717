import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { ICommunityFeed } from '@/interfaces/api/community/feed'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createCommunityFeedService = (client: AxiosCacheInstance) => ({
  async create(payload: ICommunityFeed.API.Create.Request) {
    return await axiosHandler<ICommunityFeed.API.Create.Response>(() =>
      client.post('/mobile/workCommunity/feed', payload),
    )
  },

  async update({ feedId, ...payload }: ICommunityFeed.API.Update.Request) {
    return await axiosHandler<ICommunityFeed.API.Update.Response>(() =>
      client.put(`/mobile/workCommunity/feeds/${feedId}`, payload),
    )
  },

  async delete({ feedId, memberGroupId }: ICommunityFeed.API.Delete.Request) {
    return await axiosHandler<ICommunityFeed.API.Delete.Response>(() =>
      client.delete(`/mobile/workCommunity/feeds/${feedId}/${memberGroupId}`),
    )
  },

  async getDetail({
    feedId,
    ...payload
  }: ICommunityFeed.API.GetDetail.Request) {
    return await axiosHandler<ICommunityFeed.API.GetDetail.Response>(() =>
      client.get(`/mobile/workCommunity/feeds/${feedId}`, {
        params: payload,
      }),
    )
  },

  async createReaction(payload: ICommunityFeed.API.Reaction.Request) {
    return await axiosHandler<ICommunityFeed.API.Reaction.Response>(() =>
      client.post('/mobile/workCommunity/feed/reactions', payload),
    )
  },

  async reportFeed({
    feedId,
    ...payload
  }: ICommunityFeed.API.ReportFeed.Request) {
    return await axiosHandler<ICommunityFeed.API.ReportFeed.Response>(() =>
      client.post(`/mobile/workCommunity/feeds/${feedId}/report`, payload),
    )
  },
})
