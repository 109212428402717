import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { IUrlPreview } from '@/interfaces/api/community/url-preview'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createUrlPreviewService = (client: AxiosCacheInstance) => ({
  async getUrlPreview(payload: IUrlPreview.API.GetUrlPreview.Request) {
    return await axiosHandler<IUrlPreview.API.GetUrlPreview.Response>(() =>
      client.get('/mobile/workCommunity/metalinkData', {
        params: payload,
      }),
    )
  },
})
