import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { API_CACHE_CONFIG } from '@/api/cache'
import { ICommunityCategory } from '@/interfaces/api/community/category'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createCommunityCategoryService = (client: AxiosCacheInstance) => ({
  async getList() {
    return await axiosHandler<ICommunityCategory.API.GetList.Response>(() =>
      client.get('/mobile/workCommunity/categories', {
        cache: {
          ttl: API_CACHE_CONFIG['GET /mobile/workCommunity/categories'].ttl,
        },
      }),
    )
  },
})
