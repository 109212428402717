import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { ICommunityComment } from '@/interfaces/api/community/comment'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createCommunityCommentService = (client: AxiosCacheInstance) => ({
  /**
   * 댓글, 대댓글 신고
   */
  async reportComment({
    feedId,
    replyId,
    ...payload
  }: ICommunityComment.API.ReportComment.Request) {
    return await axiosHandler<ICommunityComment.API.ReportComment.Response>(
      () =>
        client.post(
          `/mobile/workCommunity/feeds/${feedId}/replies/${replyId}/block`,
          payload,
        ),
    )
  },

  /**
   * 댓글 작성
   */
  async createComment({
    feedId,
    ...payload
  }: ICommunityComment.API.CreateComment.Request) {
    return await axiosHandler<ICommunityComment.API.CreateComment.Response>(
      () =>
        client.post(`/mobile/workCommunity/feeds/${feedId}/replies`, payload),
    )
  },

  /**
   * 대댓글 작성
   */
  async createReply({
    feedId,
    replyId,
    ...payload
  }: ICommunityComment.API.CreateReply.Request) {
    return await axiosHandler<ICommunityComment.API.CreateReply.Response>(() =>
      client.post(
        `/mobile/workCommunity/feeds/${feedId}/replies/${replyId}`,
        payload,
      ),
    )
  },

  /**
   * 댓글, 대댓글 삭제
   */
  async deleteComment({
    feedId,
    replyId,
    ...payload
  }: ICommunityComment.API.DeleteComment.Request) {
    return await axiosHandler<ICommunityComment.API.DeleteComment.Response>(
      () =>
        client.delete(
          `/mobile/workCommunity/feeds/${feedId}/replies/${replyId}`,
          {
            data: payload,
          },
        ),
    )
  },
})
