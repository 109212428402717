<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <Thumbnail
        :class="$style.thumbnail"
        :src="profileThumbnail"
        size="small"
        radius="50%"
        border
      />

      <div :class="$style.logout">
        <Button @click="$emit('click:logout')">
          <IconLogout :class="$style.icon" />
          <span>로그아웃</span>
        </Button>
      </div>

      <template v-if="username">
        <div :class="$style.username">
          <div :class="$style.text">
            {{ username }}
          </div>
        </div>
      </template>

      <template v-if="occupation">
        <div :class="$style.occupation">
          <IconWork
            :class="$style.icon"
            :color="$colorsV2['f-gray-70']"
          />
          <div :class="$style.text">
            {{ occupation }}
          </div>
        </div>
      </template>

      <template v-if="email">
        <div :class="$style.email">
          <IconEmail
            :class="$style.icon"
            :color="$colorsV2['f-gray-70']"
          />
          <div :class="$style.text">
            {{ email }}
          </div>
        </div>
      </template>
    </div>

    <hr />

    <div :class="$style.btns">
      <Button
        :class="$style.btn"
        theme="gray-90"
        @click="$emit('click:my-page')"
      >
        <span class="font-weight-normal">내계정 관리</span>
      </Button>
      <Button
        :class="$style.btn"
        theme="gray-90"
        @click="$emit('click:reset-password')"
      >
        <span class="font-weight-normal">비밀번호 변경</span>
      </Button>
    </div>

    <div
      v-if="computedIsShowSubscriptions"
      :class="$style.subscriptions"
    >
      <div :class="$style.subscriptionsTitle">
        <img
          src="@/assets/images/v2/icon/20px/submembership_illust.svg"
          alt="구독 서비스 아이콘"
        />
        {{ computedServiceName }}
      </div>
      <span :class="$style.subscriptionsDesc"
        >{{ computedServiceName }}를 이용하고 계세요!</span
      >
      <Button
        v-if="computedSelectedGroup?.isActive"
        @click="$emit('click:subscriptions-my')"
        >서비스 관리
      </Button>
      <Button
        v-else
        @click="$emit('click:subscriptions')"
        >{{computedServiceShortName}} 결제하기
      </Button>
    </div>

    <hr />

    <div :class="$style.memberGroups">
      <template v-if="memberGroups.length">
        <template
          v-for="(group, key) in memberGroups"
          :key="key"
        >
          <AccountGroupItem
            :title="getGroupName(group)"
            :desc="getGroupDesc(group)"
            :teamName="group.teamName"
            :selected="isSelectedGroup(group)"
            :disabled="isDisabledGroup(group)"
            :onClick="() => onGroupClick(group)"
          />
        </template>
      </template>
      <template v-else>
        <div :class="$style.empty">소속된 그룹이 없습니다</div>
      </template>
    </div>

    <hr />

    <div :class="$style.footer">
      <Button
        theme="gray-90"
        size="large"
        @click="$emit('click:invite')"
      >
        소속그룹 초대링크 직접 입력
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  watch,
} from 'vue'

import Thumbnail from '@/components/v2/Thumbnail.vue'
import Button from '@/components/v2/button/Button.vue'
import AccountGroupItem from '@/components/v2/dropdown/AccountGroupItem.vue'
import IconEmail from '@/components/v2/icon/IconEmail.vue'
import IconLogout from '@/components/v2/icon/IconLogout.vue'
import IconWork from '@/components/v2/icon/IconWork.vue'

import { SERVICE_FULL_NAME, SERVICE_SHORT_NAME } from '@/const/subscriptions'
import { SessionStore } from '@/interfaces/store/session'

export default defineComponent({
  components: {
    Thumbnail,
    IconLogout,
    IconWork,
    IconEmail,
    Button,
    AccountGroupItem,
  },
  props: {
    profileThumbnail: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    username: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    occupation: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    email: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    memberGroups: {
      type: Array as PropType<SessionStore.State.MemberGroupListItem[]>,
      default: () => [],
    },
    selectedMemberGroupId: Number,
  },
  emits: {
    'click:logout': null,
    'click:my-page': null,
    'click:subscriptions-my': null,
    'click:subscriptions': null,
    'click:reset-password': null,
    'click:invite': null,
    'update:selectedMemberGroupId': (val?: number) => {
      return typeof val === 'number' || typeof val === 'undefined'
    },
    'change:member-group': (group: SessionStore.State.MemberGroupListItem) => {
      return !!group
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      selectedMemberGroupId: undefined as number | undefined,
    })

    const computedIsShowSubscriptions = computed(() => {
      const selectedMemberGroup = props.memberGroups.find(
        (group) => group.memberGroupId === state.selectedMemberGroupId,
      )

      const isGroupForMembershipSubscription =
        selectedMemberGroup?.source === 'membership_subscription'
      const hasPermission =
        selectedMemberGroup?.role === 'master' ||
        selectedMemberGroup?.role === 'admin'

      return isGroupForMembershipSubscription && hasPermission
    })

    const computedServiceName = computed(() => {
      return SERVICE_FULL_NAME
    })

    const computedServiceShortName = computed(() => {
      return SERVICE_SHORT_NAME
    })

    const computedSelectedGroup = computed(() => {
      const selectedMemberGroup = props.memberGroups.find(
        (group) => group.memberGroupId === state.selectedMemberGroupId,
      )

      return selectedMemberGroup
    })

    onBeforeMount(() => {
      state.selectedMemberGroupId = props.selectedMemberGroupId
    })

    function getGroupName(group: SessionStore.State.MemberGroupListItem) {
      const prefix = group.source === 'membership_subscription' ? '[구독] ' : ''
      return `${prefix}${group.memberGroupName}`
    }

    function getGroupDesc(group: SessionStore.State.MemberGroupListItem) {
      if (group.role === 'guest') {
        return '승인 대기 중입니다.'
      }

      if (!group.isActive) {
        return `시설 이용은 ${group.source === 'membership_subscription' ? '구독' : '계약'} 시작일부터 가능합니다.`
      }

      return ''
    }

    function isSelectedGroup(group: SessionStore.State.MemberGroupListItem) {
      if (!state.selectedMemberGroupId || isDisabledGroup(group)) {
        return false
      }

      return group.memberGroupId === state.selectedMemberGroupId
    }

    function isDisabledGroup(group: SessionStore.State.MemberGroupListItem) {
      return group.role === 'guest'
    }

    function onGroupClick(group: SessionStore.State.MemberGroupListItem) {
      if (isDisabledGroup(group)) {
        return
      }

      if (state.selectedMemberGroupId === group.memberGroupId) {
        return
      }

      state.selectedMemberGroupId = group.memberGroupId

      emit('update:selectedMemberGroupId', group.memberGroupId)
      emit('change:member-group', group)
    }

    return {
      // computed
      computedIsShowSubscriptions,
      computedServiceName,
      computedServiceShortName,
      computedSelectedGroup,

      // func
      getGroupName,
      getGroupDesc,
      isSelectedGroup,
      isDisabledGroup,
      onGroupClick,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  padding: 16px;
  border-radius: 16px;
  background-color: $f-primary-black;
  width: 320px;
  color: $f-primary-white;

  hr {
    margin: 0 16px;
    border: 0;
    background-color: $f-gray-90;
    height: 1px;
  }

  .header {
    position: relative;
    padding: 16px;
    display: flex;
    flex-direction: column;

    .logout {
      @include font_v2('ko', 12px, 400);

      position: absolute;
      top: 16px;
      right: 16px;

      span {
        color: $f-gray-30;
      }

      .icon {
        margin-right: 4px;
      }
    }

    .thumbnail {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .username,
    .occupation,
    .email {
      display: flex;
      align-items: center;

      .text {
        min-width: 0;
        word-break: break-all;
        flex: 1;
      }
    }

    .occupation,
    .email {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        margin-right: 8px;
      }
    }

    .username {
      @include font_v2('ko', 16px, 500);

      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .occupation {
      @include font_v2('ko', 14px, 400);
      color: $f-gray-45;
    }

    .email {
      @include font_v2('en', 14px, 500);
    }
  }

  .btns {
    display: flex;
    padding: 16px;

    .btn {
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .subscriptions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px;
    background-color: rgba(252, 110, 46, 0.1);
    border: 1px solid rgba(252, 110, 46, 0.2);
    border-radius: 12px;
    margin: 0 16px 17px;

    &Title {
      @include font_v2('ko', 12px, 500);

      color: $f-secondary-orange;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &Desc {
      @include font_v2('ko', 12px, 400);

      color: rgba(255, 255, 255, 0.8);
    }
  }

  .memberGroups {
    margin: 0;
    max-height: 292px;
    overflow-y: auto;

    .empty {
      @include font_v2('ko', 14px, 400);

      padding: 32px 16px 16px;
      color: $f-gray-60;
      text-align: center;
    }
  }

  .footer {
    padding: 16px;
  }
}
</style>
